<template>

<div class="paginate" v-if="pages > 1">

	<div v-for="page in pages" :key="page" class="paginate-item" :class="{'is-active': page === current}" v-on:click="$emit('change', page)">
		{{ page }}
	</div>

</div>

</template>

<script>

export default {

	props: ['total', 'current', 'perpage'],

	computed: {

		pages: function() {

			return Math.ceil(this.total / this.perpage)

		}

	}

}

</script>

<style scoped>

.paginate {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.paginate-item {
	min-width: 32px;
	text-align: center;
	line-height: 32px;
	font-size: 16px;
	color: #343434;
	background-color: #f5f5f5;
	height: 32px;
	padding: 0px 10px;
	border-radius: 4px;
	margin: 2px;
	cursor: pointer;
}

.paginate-item.is-active {
	color: #fff;
	background-color: #1277d4;
}

</style>
